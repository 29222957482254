import React from 'react';
import { Typography, Box, Link } from '@mui/material';

function Certifications() {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Certifications
      </Typography>
      <ul>
        <li>
          <Link href="https://www.credly.com/badges/a120c067-71b2-4120-9fc9-8eb5ae572748" target="_blank" rel="noopener noreferrer">
            AWS Solutions Architect Associate
          </Link>
        </li>
        <li>
                  <Link href="https://www.credly.com/badges/4286ca68-37ba-456a-8b2d-02e373885074" target="_blank" rel="noopener noreferrer">
                    AWS Developer Associate
                  </Link>
        </li>
        <li>
          <Link href="https://www.credly.com/badges/7cec409a-0ff2-4f8c-8241-48ca153eee4b" target="_blank" rel="noopener noreferrer">
            HashiCorp Terraform Associate
          </Link>
        </li>
      </ul>
    </Box>
  );
}

export default Certifications;
