import React from 'react';
//import { ThemeProvider } from '@mui/material/styles';
//import theme from './theme';
import { Container, Typography, Link, Box } from '@mui/material';
import Objective from './components/Objective';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Education from './components/Education';
import Skills from './components/Skills';
import Certifications from './components/Certifications';

function App() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          PRABHU RAJENDRAN
        </Typography>
        <Contact />
        <Objective />
        <Skills />
        <Experience />
        <Education />
        <Certifications />
      </Box>
    </Container>
  );
}

export default App;
