import React from 'react';
import { Typography, Box } from '@mui/material';

function Skills() {
  return (
    <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Skills
      </Typography>
      <Typography variant="body1">
        Oracle Java, Amazon Web Services (AWS), Apache Kafka, Spring Boot, Spring Framework, PostgreSQL, MySQL, Docker, Terraform, Elasticsearch
      </Typography>
    </Box>
  );
}

export default Skills;
