import React from 'react';
import { Typography, Box } from '@mui/material';

function Education() {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Education
      </Typography>

      <Typography variant="h6" component="h3">
        Master of Science (Computer Science)
      </Typography>
      <Typography variant="body1">
        University of Illinois | Springfield, Illinois <br />
        Expected: Dec 2024
      </Typography>

      <Typography variant="h6" component="h3">
        Bachelor of Technology (Information Technology)
      </Typography>
      <Typography variant="body1">
        Karpagam College of Engineering | Coimbatore, India <br />
        Graduated: May 2017
      </Typography>

      <Typography variant="body1" paragraph>
        Courses: Data Structures, OOPS, Computer Architecture, System Software, Computer Networks, Java, C, C++, Database Management Systems.
      </Typography>
    </Box>
  );
}

export default Education;
