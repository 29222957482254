import React from 'react';
import { Typography, Box, Link } from '@mui/material';

function Contact() {
  return (
    <Box sx={{ my: 3 }}>
      <Typography variant="body1" paragraph>
        <strong>Email:</strong> <Link href="mailto:rajendranprabhu.r@gmail.com">rajendranprabhu.r@gmail.com</Link>
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Phone:</strong> +1-217-553-9822
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Website:</strong> <Link href="http://prabhurajendran.in" target="_blank" rel="noopener noreferrer">prabhurajendran.in</Link>
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>LinkedIn:</strong> <Link href="https://www.linkedin.com/in/prabhur-rajendran/" target="_blank" rel="noopener noreferrer">LinkedIn Profile</Link>
      </Typography>
    </Box>
  );
}

export default Contact;
