import React from 'react';
import { Typography, Box } from '@mui/material';

function Objective() {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Objective
      </Typography>
      <Typography variant="body1" paragraph>
        Results-driven Software Engineer with a proven track record of successfully leading cross-functional teams in the development of complex software systems. Looking for a challenging role where I can leverage my technical expertise to architect and deliver cutting-edge solutions.
        <Typography component="span" sx={{ fontWeight: 'bold', display: 'block', mt: 2 }}>
          Over 7 years of experience building products and providing support to websites and applications. Expert in Java, Spring, PostgreSQL, Hibernate, Aerospike (NoSQL), Kafka, AWS Certified (Developer & System Architect Associate), and Terraform Certified Associate.
        </Typography>
      </Typography>
    </Box>
  );
}

export default Objective;
