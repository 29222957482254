import React from 'react';
import { Typography, Link, Box } from '@mui/material';

function Experience() {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Experience(7 Years of Backend Engineering & Devops)
      </Typography>

      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
              Technical Lead
      </Typography>
      <Typography variant="body1">
        Tripjack Pvt Ltd | New Delhi, India <br />
        Apr 2022 - Dec 2023
      </Typography>
      <Link href="https://tripjack.com/" target="_blank" rel="noopener noreferrer">
        tripjack.com
      </Link>
      <ul>
        <li>Designed and constructed a sophisticated, highly scalable, and low-maintenance flight reservation system.</li>
        <li>Addressed various challenges including autoscaling, microservices architecture, and a complex rule engine.</li>
        <li>Pioneered a search system that handles over 45 million queries daily.</li>
        <li>Proficient in a wide range of technologies, including Spring Boot, Java, Aerospike, Elasticsearch, Logstash, AWS, Microservices, Kafka, Git, and Terraform.</li>
      </ul>

      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
        Software Engineer I & II
      </Typography>
      <Typography variant="body1">
        Tripjack Pvt Ltd (formerly Technogram Solutions) | New Delhi, India <br />
        Jul 2018 - Mar 2022
      </Typography>
      <Link href="https://tripjack.com/" target="_blank" rel="noopener noreferrer">
        tripjack.com
      </Link>
      <ul>
        <li>Lead Flight product team and integrated 25+ suppliers (REST/SOAP), designed all the processes from offline inventory management, handling commercials and amendments, caching data, identifying bottlenecks.</li>
        <li>Proactively identified and resolved bottlenecks to optimize system performance and problem-solving.</li>
        <li>Migrated complete system from EC2 to ECS, decreased production costs by 30% by moving the entire system to Auto Scaling.</li>
        <li>Implemented Access Control, Abuse Control, Customized Rule Engine, Duplicate Order Restriction Framework, and NoShow Booking Refund automation.</li>
        <li>Configured and maintained Jenkins to create CI/CD pipelines, tag-based deployment, send email notifications, etc., ensuring an efficient and less error-prone build and development process. Used by QA and development team to build, configure jobs, and test results.</li>
        <li>Implemented Caching Framework using Aerospike.</li>
      </ul>

      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
        Software Engineer & Internship
      </Typography>
      <Typography variant="body1">
        Via.com | Bengaluru, India <br />
        Jul 2016 - Jun 2018
      </Typography>
      <Link href="https://via.com" target="_blank" rel="noopener noreferrer">
        via.com
      </Link>
      <ul>
        <li>Integrated REST & SOAP based 3 APIs.</li>
        <li>Implemented Discounted Fare System Logic for International Content.</li>
        <li>Optimized Search by introducing NoSearchResult Login to avoid L2B and load on servers.</li>
        <li>Implemented Template Based Creation of - Distributor & White Label system for new users.</li>
      </ul>
    </Box>
  );
}

export default Experience;
